.instagram {
	margin: 0px 32px;
	margin-bottom: 16px;
}
.hero {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.hero .titleArea {
	background-image: url("../imgs/heros/group1.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.hero2 .titleArea {
	background-image: url("../imgs/heros/group2.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.hero3 .titleArea {
	background-image: url("../imgs/heros/group3.jpeg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
