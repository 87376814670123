/* body,
h1 {
  color: #232e63;
}
.content {
  margin: 0 8px;
}

a {
  text-decoration: underline;
  color: #232e63;
}

a:visited {
  color: inherit;
}

.gameButton {
  padding: 8px;
  border: 1px solid #232e63;
  margin: 4px;
  cursor: pointer;
  background-color: white;
  color: #232e63;
}

.gameButton:hover {
  padding: 8px;
  background-color: #232e63;
  margin: 4px;
  color: white;
}

.selected {
  padding: 8px;
  background-color: #232e63;
  margin: 4px;
  color: white;
  border: 1px solid #232e63;
} */

@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@font-face {
	font-family: "Open Sans Condensed";
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensanscondensed/v23/z7NHdQDnbTkabZAIOl9il_O6KJj73e7Fd_-7suD8S72Q.ttf)
		format("truetype");
}

@font-face {
	font-family: "Open Sans Condensed";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensanscondensed/v23/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuvMQg.ttf)
		format("truetype");
}

@font-face {
	font-family: "Open Sans Condensed";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensanscondensed/v23/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDuvMQg.ttf)
		format("truetype");
}

:root {
	--main-color: #232e63;
	--main-transparent: rgba(35, 46, 99, 0.7);
	--white-transparent: rgba(255, 255, 255, 0.6);
	--white-less-transparent: rgba(255, 255, 255, 0.4);
	--sec-color: #3e52b3;
	--sec-transparent: rgba(62, 82, 179, 0.6);
	--silver: #c1c1c1;
	--siver-transparent: rgba(193, 193, 193, 0.6);
	--shadow-color: 231deg 59% 6%;
	--shadow-elevation: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
		0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
		2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
		5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);
}

body {
	margin: 0;
	font-family: Open Sans;
	font-size: 18px;
	line-height: 1.44em;
	font-weight: 400;
	color: var(--main-color);
	background-color: #fff;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
}

p {
	font-family: Open Sans;
	font-size: 16px;
	line-height: 22px;
	font-style: normal;
	font-weight: 400;
	text-transform: none;
	margin-top: 8px;
	margin-bottom: 8px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	text-align: center;
}

h1 {
	font-family: Open Sans Condensed;
	font-size: 60px;
	line-height: 70px;
}
h2 {
	font-family: Open Sans Condensed;
	font-size: 40px;
	line-height: 50px;
	font-style: normal;
	font-weight: 800;
	letter-spacing: 1px;
	text-transform: uppercase;
}

h3 {
	font-family: Open Sans Condensed;
	font-size: 34px;
	line-height: 45px;
	font-style: normal;
	font-weight: 800;
	letter-spacing: 1px;
	text-transform: uppercase;
}

h4 {
	font-family: Open Sans Condensed;
	font-size: 25px;
	line-height: 35px;
	font-weight: 700;
	text-transform: uppercase;
}

h5 {
	font-family: Open Sans Condensed;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
}

h6 {
	font-family: Open Sans Condensed;
	font-size: 13px;
	font-style: normal;
	font-weight: 300;
	letter-spacing: 1px;
	text-transform: uppercase;
}

a,
p a {
	font-style: normal;
	font-weight: 400;
	color: var(--main-color);
}

a:hover,
p a:hover {
	color: var(--sec-color);
	text-decoration: underline;
}

.centered-items {
	text-align: center;
}
.importanttext {
	color: var(--main-color);
}
/* CARD */
.card {
	box-shadow: var(--shadow-elevation);
	margin: 0 auto 16px;
	width: 90vw;
	border-radius: 8px;
	overflow: hidden;
	min-height: 150px;
	display: flex;
	flex-direction: column;
	max-width: 700px;
}
.grass {
	/* background-image: url("./imgs/grass.jpg"); */
	background-repeat: no-repeat;
	background-size: contain;
}

.logobg {
	background-image: url("./imgs/logobg.jpeg");
	background-size: 50px;
}
.card h5,
.card h6 {
	margin: 4px;
}
.titleArea {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	min-height: 80px;
	aspect-ratio: 2;
}
.titleArea h2,
.titleArea h3,
.titleArea h5,
.titleArea h6,
.titleArea p {
	padding: 16px;
	background-color: var(--white-transparent);
	margin: 2px 0;
}
.logobg h3 {
	background-color: white;
}
.videoContainer {
	flex: none;
	position: relative;
}
.videoContainer video {
	width: 100%;
	height: 100%;
	position: absolute;
	object-fit: cover;
	z-index: 0;
}
.videoContainer .caption {
	z-index: 1;
	position: relative;
	/* text-align: center; */
	/* padding: 16px; */
	/* background-color:rgba(255,255,255,0.9); */
	/* box-shadow: var(--shadow-elevation-high); */
}
.bottomText {
	background-color: var(--main-color);
	color: white;
	margin: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0 16px;
}
.bottomText h6 {
	margin: 8px;
	color: white;
	text-align: left;
}
.bottomText p {
	color: white;
	margin: 16px 0;
}
.actionButton {
	background-color: white;
	box-shadow: 2px 3px 6px rgb(100 100 100 / 50%);
	text-transform: uppercase;
	font-size: 2vh;
	font-weight: 700;
	width: 80%;
	height: 5vh;
	border-radius: 10px;
	border: 0;
	cursor: pointer;
	margin: 8px;
	color: var(--main-color);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.actionButton caption {
	text-align: center;
	font-weight: 100;
}
.card ol {
	display: block;
	font-size: 14px;
}
.card ul {
	display: block;
}
.card li {
	float: none;
	margin: 8px 0;
}
.bottomText a {
	color: white;
	text-decoration: underline;
}
.card li a {
	display: inline-block;
	padding: 0;
}

/* BUTTON */
button {
	border: none;
	background-color: transparent;
	padding: 0;
	margin: 0;
	cursor: pointer;
}
button:hover {
	opacity: 0.6;
}
button:disabled {
	border: 1px solid gray;
	color: gray;
	cursor: unset;
}
button:disabled:hover {
	opacity: 1;
}

/* TABLE */
table {
	border-collapse: collapse;
}
thead {
	font-family: Open Sans Condensed;
	font-weight: 800;
}
.boldrow {
	font-family: Open Sans Condensed;
	font-weight: 800;
}
td,
th {
	border: 1px solid #ddd;
	padding: 4px;
}
/* Dropdown */
*,
*::before,
*::after {
	box-sizing: border-box;
}
select {
	/* A reset of styles, including removing the default dropdown arrow */
	appearance: none;
	/* Additional resets for further consistency */
	background-color: transparent;
	border: none;
	margin: 0;
	width: 100%;
	font-family: inherit;
	font-size: inherit;
	cursor: inherit;
	line-height: inherit;
}
.dropdown {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 8px 0;
}
.dropdown label {
	margin: 0 8px;
}
.select {
	margin: 0 8px;
	border: 1px solid var(--main-color);
	border-radius: 0.25em;
	padding: 4px 8px;
	cursor: pointer;
	line-height: 1.1;
	background-color: #fff;
	background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}

/* MEDIA */
@media only screen and (max-width: 1024px) {
	h1 {
		font-size: 45px !important;
		line-height: 55px !important;
	}

	h2 {
		font-size: 35px !important;
		line-height: 45px !important;
	}

	h3 {
		font-size: 30px !important;
		line-height: 40px !important;
	}
	h4 {
		font-size: 25px !important;
		line-height: 30px !important;
	}
	h5 {
		font-size: 20px !important;
		line-height: 25px !important;
	}
	.titleArea h2,
	.titleArea h3,
	.titleArea h5,
	.titleArea h6,
	.titleArea p {
		padding: 12px;
	}
}
@media only screen and (max-width: 680px) {
	h1 {
		font-size: 35px !important;
		line-height: 45px !important;
	}

	h2 {
		font-size: 27px !important;
		line-height: 30px !important;
	}

	h3 {
		font-size: 25px !important;
		line-height: 35px !important;
	}
	h4 {
		font-size: 20px !important;
		line-height: 25px !important;
	}
	h5 {
		font-size: 15px !important;
		line-height: 20px !important;
	}
	.titleArea h2,
	.titleArea h3,
	.titleArea h5,
	.titleArea h6,
	.titleArea p {
		padding: 6px;
	}
}
@media only screen and (max-width: 320px) {
	.gradient h5 {
		letter-spacing: 2px;
	}
	h1 {
		font-size: 30px !important;
		line-height: 35px !important;
	}

	h2 {
		font-size: 22px !important;
		line-height: 26px !important;
	}

	h3 {
		font-size: 20px !important;
		line-height: 30px !important;
	}
	h4 {
		font-size: 15px !important;
		line-height: 20px !important;
	}
	h5 {
		font-size: 10px !important;
		line-height: 15px !important;
	}
	.titleArea h2,
	.titleArea h3,
	.titleArea h5,
	.titleArea h6,
	.titleArea p {
		padding: 3px;
	}
}
