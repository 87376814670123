ul {
  overflow: visible;
}

ul.slider {
  justify-content: flex-start;
  overflow:visible;
}
li.slide a {
  display: inline;
  padding: 0;
}
.coachButton {
  margin: 4px 4px;
  border: none;
  color: #232e63;
  padding: 4px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border: 1px solid #232e63;
  border-radius: 4px;
}
.coachButton p{
  font-weight:bold;
  margin: 0px;
}
.buttonGroup {
  margin: 16px 0;
}
.buttonGroup > div {
  margin: 0 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.coachView {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.coachView a {
  text-decoration: underline;
}
