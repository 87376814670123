footer{
    width:100vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    flex-direction: column;
    margin-bottom: 16px;
}
.social{
    background-color: var(--main-color);
    margin: 0 8px;
    border-radius: 8px;
    box-shadow: var(--shadow-elevation);
    display: flex;
    justify-content: center;
    align-items: center;
}
.social img{
    width: 32px;
    margin: 8px;
}

.footerLinks{
    display: flex;
}