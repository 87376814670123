.title {
  padding: 8px 0px;
  background-color: var(--main-color);
  color: white;
  width: 100vw;
}
.title img {
  height: 40px;
  border-radius: 100px;
  margin-right: 4px;
}
.seasonSelector{
  display: flex;
  justify-content: center;
  background-color: var(--main-color);
  padding-bottom: 8px;
  align-self: center;
}
.seasonSelector div{
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.seasonSelector div:last-child{
  justify-content: flex-start;
}

.seasonSelector a{
  color: white;
  margin: 0 16px;
  opacity: 0.8;
  text-decoration: none;
  font-size: 14px;
  border: 1px solid white;
  padding: 2px 4px;
  border-radius: 8px;
}
.seasonSelector .active{
  font-weight: bold;
  opacity: 1;
  color: var(--main-color);
  background-color: white;
}
/* Gradient */
.gradient{
  letter-spacing: 5px;
  background: linear-gradient(-45deg,white,white,var(--main-color),white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 300%;
  animation: gradient-flow 8s ease-in-out infinite;
  -webkit-animation: gradient-flow 8s ease-in-out infinite;
  color: transparent;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
}
.gradient h5{
  flex: 1
}
/* Animation */
.animationModule{
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: .2s;
  animation-duration: .2s;
}

@-webkit-keyframes gradient-flow {
  0% {
      background-position:0 50%
  }

  50% {
      background-position:100% 50%
  }

  to {
      background-position:0 50%
  }
}

@keyframes gradient-flow {
  0% {
      background-position:0 50%
  }

  50% {
      background-position:100% 50%
  }

  to {
      background-position:0 50%
  }
}

/* NAVIGATION */
.nav{
  font-size: 14px;
  margin-bottom: 16px;
}
ul {
  list-style-type: none;
  margin: 0px 4px;
  padding: 0;
  overflow: hidden;
  top: 0;
  display: flex;
  justify-content: center;
}

li {
  float: left;
  margin: 0px 2px;
}

li a {
  display: block;
  text-align: center;
  padding: 4px 8px;
  cursor: pointer;
  text-decoration: none;
}

li a:hover,
.active a {
  background-color: #232e63;
  color: white;
}
